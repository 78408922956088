import { motion } from 'framer-motion';
import { useState } from 'react';
import WeddingGallery from './WeddingGallery';
import engagement1 from '../assets/engagement/Engagement1.png';
import engagement2 from '../assets/engagement/Engagement2.png';
import destination1 from '../assets/destination/Destination1.png';
import destination2 from '../assets/destination/Destination2.png';
import destination3 from '../assets/destination/Destination3.png';
import ethnic1 from '../assets/ethnic/Ethnic1.png';
import ethnic2 from '../assets/ethnic/Ethnic2.png';
import ethnic3 from '../assets/ethnic/Ethnic3.png';
import ethnic4 from '../assets/ethnic/Ethnic4.png';
import ethnic5 from '../assets/ethnic/Ethnic5.png';
import urban1 from '../assets/urban/Urban1.png';
import urban2 from '../assets/urban/Urban2.png';
import urban3 from '../assets/urban/Urban3.png';
import urban4 from '../assets/urban/Urban4.png';
import urban5 from '../assets/urban/Urban5.png';

interface Category {
  id: string;
  title: string;
  images: string[];
  gradient: string;
  description: string;
}

const categories: Category[] = [
  {
    id: 'engagements',
    title: 'Engagements',
    description: 'Intimate celebrations marking the beginning of forever',
    gradient: 'from-rose-500/20 to-pink-500/20',
    images: [engagement1, engagement2]
  },
  {
    id: 'destination',
    title: 'Destination Weddings',
    description: 'Magical moments in breathtaking locations',
    gradient: 'from-blue-500/20 to-cyan-500/20',
    images: [destination1, destination2, destination3]
  },
  {
    id: 'ethnic',
    title: 'Ethnic Weddings',
    description: 'Celebrating cultural heritage and traditions',
    gradient: 'from-amber-500/20 to-orange-500/20',
    images: [ethnic1, ethnic2, ethnic3, ethnic4, ethnic5]
  },
  {
    id: 'urban',
    title: 'Urban Weddings',
    description: 'Contemporary elegance in modern settings',
    gradient: 'from-emerald-500/20 to-teal-500/20',
    images: [urban1, urban2, urban3, urban4, urban5]
  }
];

const WeddingCategories = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  return (
    <section id="wedding-collections" className="relative min-h-[40rem] py-24 px-8 bg-gradient-to-b from-[#1A1A1F] to-[#0A0A0F] overflow-hidden">
      {/* Background Gradients */}
      <div className="absolute inset-0 flex justify-between items-center pointer-events-none">
        {categories.map((cat) => (
          <div
            key={cat.id}
            className={`w-1/4 h-2/3 bg-gradient-to-b ${cat.gradient} blur-[100px] opacity-30`}
          />
        ))}
      </div>

      {/* Content */}
      <div className="max-w-7xl mx-auto relative">
        {/* Title */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-24"
        >
          <h2 
            className="text-4xl md:text-6xl font-bold"
            style={{
              background: 'linear-gradient(to right, #00C6FF, #0072FF, #FFB88C, #FF6F61)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent',
              paddingBottom: '1rem'
            }}
          >
            Our Wedding Collections
          </h2>
          <motion.div 
            className="h-1 w-24 mx-auto mt-6 rounded-full"
            style={{
              background: 'linear-gradient(to right, #00C6FF, #0072FF, #FFB88C, #FF6F61)'
            }}
          />
        </motion.div>

        {selectedCategory ? (
          <WeddingGallery 
            category={categories.find(c => c.id === selectedCategory)!}
            onClose={() => setSelectedCategory(null)}
          />
        ) : (
          <motion.div 
            className="grid grid-cols-2 lg:grid-cols-4 gap-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {categories.map((category) => (
              <motion.div
                key={category.id}
                className="relative group cursor-pointer"
                whileHover={{ scale: 1.02 }}
                onClick={() => setSelectedCategory(category.id)}
              >
                <div className={`absolute inset-0 bg-gradient-to-br ${category.gradient} opacity-20 rounded-2xl`} />
                <div className="relative h-80 border border-white/10 rounded-2xl p-8 flex flex-col justify-end overflow-hidden group-hover:border-white/30 transition-colors duration-300">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h3 className="text-2xl font-semibold text-white mb-2">{category.title}</h3>
                    <p className="text-gray-400 text-sm">{category.description}</p>
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default WeddingCategories;