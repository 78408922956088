import { motion } from 'framer-motion';
// Import all partnership logos
import Apollo from '../assets/partnerships/Apollo.png';
import Appaswamy from '../assets/partnerships/Appaswamy.png';
import Ceebros from '../assets/partnerships/Ceebros.png';
import VBJ from '../assets/partnerships/VBJ.png';
import IndiaCements from '../assets/partnerships/IndiaCements.png';
import GRT from '../assets/partnerships/GRT.png';
import Kalyan from '../assets/partnerships/Kalyan.png';
import Kumaran from '../assets/partnerships/Kumaran.png';
import Nalli from '../assets/partnerships/Nalli.png';
import Pothys from '../assets/partnerships/Pothys.png';
import Sanmar from '../assets/partnerships/Sanmar.png';
import SaravanaStores from '../assets/partnerships/SaravanaStores.png';
import SunTV from '../assets/partnerships/SunTV.png';
import Sweets from '../assets/partnerships/Sweets.png';
import TVS from '../assets/partnerships/TVS.png';
import Vasanta from '../assets/partnerships/Vasanta.png';
import Kalaignar from '../assets/partnerships/Kalaignar.png';

const Partnerships: React.FC = () => {
    const topPartners = [
        { id: 1, logo: Apollo, name: 'Apollo Hospitals' },
        { id: 2, logo: Appaswamy, name: 'Appaswamy' },
        { id: 3, logo: Ceebros, name: 'Ceebros' },
        { id: 4, logo: VBJ, name: 'VBJ' },
        { id: 5, logo: IndiaCements, name: 'IndiaCements' },
        { id: 6, logo: GRT, name: 'GRT' },
        { id: 7, logo: Kalyan, name: 'Kalyan Jewellers' },
        { id: 8, logo: Kumaran, name: 'Kumaran' },
        { id: 9, logo: Nalli, name: 'Nalli' },
    ];

    const bottomPartners = [
        { id: 10, logo: Pothys, name: 'Pothys' },
        { id: 11, logo: Sanmar, name: 'Sanmar' },
        { id: 12, logo: SaravanaStores, name: 'Saravana Stores' },
        { id: 14, logo: SunTV, name: 'SunTV' },
        { id: 15, logo: Sweets, name: 'Sri Krishna Sweets' },
        { id: 16, logo: TVS, name: 'TVS' },
        { id: 17, logo: Vasanta, name: 'Vasanta' },
        { id: 18, logo: Kalaignar, name: 'Kalaignar' },
    ];

    return (
        <section id="partners" className="relative min-h-[40rem] py-24 bg-gradient-to-b from-[#0A0A0F] to-[#12121A] overflow-hidden">
            {/* Background Decorative Elements */}
            <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-l from-[#00C6FF]/10 to-[#6EE7B7]/10 blur-[100px] rounded-full" />
            <div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-gradient-to-r from-[#00C6FF]/10 to-[#6EE7B7]/10 blur-[100px] rounded-full" />

            {/* Section Title */}
            <div className="text-center mb-20">
                <div className="relative inline-block">
                    <motion.h2 
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="text-4xl md:text-6xl font-bold mb-2"
                        style={{
                            background: 'linear-gradient(to right, #00C6FF, #2DD4BF, #6EE7B7)',
                            WebkitBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: 'transparent',
                        }}
                    >
                        Our Trusted Partners
                    </motion.h2>
                    <motion.div 
                        initial={{ opacity: 0, width: 0 }}
                        whileInView={{ opacity: 1, width: "120px" }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                        className="h-1 w-24 mx-auto mt-6 rounded-full bg-gradient-to-r from-[#00C6FF] via-[#2DD4BF] to-[#6EE7B7]"
                    />
                </div>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="text-gray-400 max-w-2xl mx-auto px-6"
                >
                    Collaborating with the finest venues and service providers to create unforgettable experiences
                </motion.p>
            </div>

            {/* Scrolling Partners Rails */}
            <div className="relative max-w-[100vw] overflow-hidden">
                {/* Top Rail - Moving Left - White Background */}
                <motion.div 
                    className="flex gap-8 mb-8"
                    animate={{
                        x: ["0%", "-50%"]
                    }}
                    transition={{
                        x: {
                            repeat: Infinity,
                            repeatType: "loop",
                            duration: 25,
                            ease: "linear",
                        },
                    }}
                    style={{
                        width: "fit-content"
                    }}
                >
                    {[...topPartners, ...topPartners].map((partner, index) => (
                        <div 
                            key={`${partner.id}-${index}`}
                            className="relative min-w-[300px] h-24 bg-white rounded-xl flex items-center justify-center p-4"
                        >
                            <img 
                                src={partner.logo} 
                                alt={partner.name}
                                className={`max-w-full max-h-full object-contain ${
                                    partner.id === 5 ? 'w-40 h-40' :  // Apollo
                                    partner.id === 3 ? 'w-44 h-44   ' :  // Ceebros
                                    partner.id === 4 ? 'w-44 h-44' :  // Kalyan
                                    'w-auto h-auto'
                                }`}
                            />
                        </div>
                    ))}
                </motion.div>

                {/* Bottom Rail - Moving Right - Bordered Segments */}
                <motion.div 
                    className="flex gap-8"
                    animate={{
                        x: ["-50%", "0%"]
                    }}
                    transition={{
                        x: {
                            repeat: Infinity,
                            repeatType: "loop",
                            duration: 25,
                            ease: "linear",
                        },
                    }}
                    style={{
                        width: "fit-content"
                    }}
                >
                    {[...bottomPartners, ...bottomPartners].map((partner, index) => (
                        <div 
                            key={`${partner.id}-${index}`}
                            className="relative min-w-[300px] h-24 border border-[#2DD4BF]/30 rounded-xl flex items-center justify-center p-4 backdrop-blur-sm"
                        >
                            <img 
                                src={partner.logo} 
                                alt={partner.name}
                                className={`max-w-full max-h-full object-contain ${
                                    partner.id === 16 ? 'w-44 h-44' :   // Nalli
                                    partner.id === 12 ? 'w-50 h-50' :  // Saravana Stores
                                    'w-auto h-auto'                    // Default size for others
                                }`}
                            />
                        </div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

export default Partnerships;