import { motion } from 'framer-motion';
import { useState } from 'react';

interface GalleryProps {
  category: {
    id: string;
    title: string;
    images: string[];
    gradient: string;
  };
  onClose: () => void;
}

const WeddingGallery = ({ category, onClose }: GalleryProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % category.images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + category.images.length) % category.images.length);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.4 }}
      className="relative"
    >
      {/* Close Button */}
      <motion.button
        whileHover={{ scale: 1.1 }}
        onClick={onClose}
        className="absolute -top-12 right-0 text-white/60 hover:text-white z-10"
      >
        <span className="sr-only">Close gallery</span>
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </motion.button>

      {/* Gallery */}
      <div className="relative aspect-[16/9] rounded-2xl overflow-hidden">
        <motion.div
          className="relative w-full h-full"
        >
          <motion.img
            src={new URL(category.images[currentIndex], import.meta.url).href}
            alt={`${category.title} ${currentIndex + 1}`}
            className="w-full h-full object-cover"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
        </motion.div>

        {/* Navigation Arrows */}
        <div className="absolute inset-y-0 left-0 flex items-center justify-center">
          <button
            onClick={handlePrev}
            className="p-2 rounded-full bg-black/30 text-white/80 hover:bg-black/50 hover:text-white transition-colors"
            disabled={currentIndex === 0}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center justify-center">
          <button
            onClick={handleNext}
            className="p-2 rounded-full bg-black/30 text-white/80 hover:bg-black/50 hover:text-white transition-colors"
            disabled={currentIndex === category.images.length - 1}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        {/* Image Counter */}
        <div className="absolute bottom-4 right-4 bg-black/50 px-3 py-1 rounded-full text-sm text-white/90">
          {currentIndex + 1} / {category.images.length}
        </div>
      </div>
    </motion.div>
  );
};

export default WeddingGallery; 