import { motion } from 'framer-motion';

const Highlights: React.FC = () => {
    return (
        <section className="relative min-h-[40rem] py-24 px-8 bg-gradient-to-b from-[#0A0A0F] to-[#12121A] overflow-hidden">
            {/* Background Decorative Elements */}
            <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-l from-purple-500/10 to-blue-500/10 blur-[100px] rounded-full" />
            <div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-gradient-to-r from-emerald-500/10 to-cyan-500/10 blur-[100px] rounded-full" />

            <div className="max-w-7xl mx-auto relative">
                {/* Section Title */}
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-center mb-16"
                >
                    <h2 
                        className="text-4xl md:text-6xl font-bold"
                        style={{
                            background: 'linear-gradient(to right, #34D399, #14B8A6, #22C55E, #059669)',
                            WebkitBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: 'transparent',
                            paddingBottom: '1rem'
                        }}
                    >
                        Featured Highlights
                    </h2>
                    <motion.div 
                        className="h-1 w-24 mx-auto mt-6 rounded-full bg-gradient-to-r from-emerald-400 via-teal-500 to-green-600"
                    />
                </motion.div>

                {/* Video Container */}
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="relative aspect-video w-full max-w-4xl mx-auto rounded-2xl overflow-hidden"
                >
                    {/* Video Border Gradient */}
                    <div className="absolute inset-0 p-[1px] rounded-2xl bg-gradient-to-r from-blue-500/30 via-purple-500/30 to-emerald-500/30" />
                    
                    <video
                        className="w-full h-full rounded-2xl object-cover"
                        controls
                        playsInline
                        preload="none"
                    >
                        <source src="/src/assets/videos/highlights.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </motion.div>

                {/* Optional: Add a description or call-to-action below */}
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    className="text-center text-gray-400 mt-8 max-w-2xl mx-auto"
                >
                    Experience the magic of our most memorable celebrations through this carefully curated highlight reel
                </motion.p>
            </div>
        </section>
    );
};

export default Highlights;
