import { BrowserRouter as Router } from 'react-router-dom'
import Header from './components/Header';
import Hero from './components/Hero';
import CelebrityShowcase from './components/CelebrityShowcase';
import WeddingCategories from './components/WeddingCategories';
import Partnerships from './components/Partnerships';
import Highlights from './components/Highlights';
import Contact from './components/Contact';

const App: React.FC = () => {
  return (
    <Router basename={import.meta.env.BASE_URL}>
      <div className="min-h-screen bg-dark w-full">
        <Header />
        <main className="w-full">
          <Hero />
          <CelebrityShowcase />
          <WeddingCategories />
          <Partnerships />
          <Highlights />
          <Contact />
        </main>
      </div>
    </Router>
  );
}

export default App;
